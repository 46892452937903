// 公用地址
// let web = 'http://101.34.128.130:8800/gold/portal';
let web = "/wechat/";

// 接口
let url = [
  [``, ""],
  [`${web}video/index`, "1 获取课程视频详情"],
  [`${web}video/view`, "2 推动一个播放时间"],

  
];

// 抛出
export { web, url };
